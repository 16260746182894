export const CINEMA = [
  {
    quote:
      'Not exactly business motivation. But for me always represented the perfect motivational speech for a team, to understand that only together you can be strong for whatever is coming 😊',
    video: 'Braveheart: William Wallace Freedom Speech',
    author: 'Damiano Nardone',
    position: 'Vice President, PM Care Systems AG',
  },
  {
    quote:
      'Self-help Singh has become one of my favorite anti-work idols😊, his videos are hilarious and are a good reminder not to take work too seriously',
    video: 'Slow Down - Self-help Singh',
    author: 'Alvaro Gianola',
    position: 'Director People Operations Europe, Energy Vault',
  },
  {
    quote:
      'I normally get my motivation by listening to music like Leona Lewis - Footprint in the sand, Pentatonix - Hallelujah, their video to the song gives me always goosebumps. 25 for Haiti - We are the world, and many more: classic music like Beethoven 9. Symphonia, Vivaldi 4 seasons...',
    video: 'Pentatonix Hallelujah',
    author: 'Markus Wyss',
    position: 'Chief Executive Officer, CAMAG',
  },
  {
    quote:
      'If I think back the last 10 years and ask myself, what was “the most inspiring new thing” which passed my way, then it was Emotional Intelligence, the skill, which divide Leaders in great ones and poor ones. Chade Me Tang, a Google Senior, created a program: Search inside yourself with very famous co-authors',
    video: 'Search Inside Yourself by Chade-Meng Tan',
    author: 'Ralph Stadler',
    position: 'Head of Group Human Resources, TransInvest Holding AG',
  },
  {
    quote: '',
    video: 'The Art of Listening | Simon Sinek',
    author: 'Christoph von Euw',
    position: 'COO, Glacier Express AG',
  },
  {
    quote: '',
    video:
      'Keynote speaker Brian Solis on the future of customer experience design',
    author: 'Gunnar Schmidt',
    position: 'Customer Experience Manager & Scrum Master, MSD Switzerland',
  },
  {
    quote:
      'It\'s a science fiction film released in 2010, where the protagonist, a game developer named Sam Flynn, is transported into a virtual world called "The Grid" and becomes involved in a high-stakes adventure',
    video: 'Tron: Legacy',
    author: 'Gilberto Loacker',
    position: 'Founder & Chairman of the Board, Alturos Destinations AG',
  },
  {
    quote:
      'Tony Hawk landing the first ever 900 on a skateboard is a good example of dedication, persistence, and ‘never give up’ mentality',
    video: 'Tony Hawk Lands FIRST-EVER 900 | World of X Games',
    author: 'Alvaro Gianola',
    position: 'Director People Operations Europe, Energy Vault',
  },
  {
    quote: '',
    video: 'Netflix doc re Arnold Schwarzenegger',
    author: 'Christian Fillinger',
    position: 'CEO, Capptoo Group',
  },
  {
    quote: `Please look at the recent videos of Bjoerk and also Simon McBurney - they have some works that “destroy your mind”😉. I will think of some more. Of course you can allways find videos online of works of Marina Abramovic. She is for many the über-inspiration😊`,
    video:
      'Recent videos of Bjoerk and also Simon McBurney | Works of Marina Abramovic',
    author: 'Wojtek Klemm',
    position: 'Customer Success Manager / Project Management Screver/Capptoo',
  },
];

export const CINEMA_TEXT = `
  <p>
    Step-Out Cinema is a calm spot at our event. 
  </p>

  <p>
    This space is filled with inspiring videos, all generously shared by our guests.
  </p>

  <p>
    Find inspiration, pause, and reflect here.
  </p>
`;
