import React from 'react';

import {
  section,
  text,
  list,
  itemList,
  qoute,
  video,
  author,
  position,
} from './styles.module.scss';

const Cinema = ({ data }) => {
  return (
    <section className={section}>
      <div className="">
        <div
          className={text}
          dangerouslySetInnerHTML={{ __html: data.CINEMA_TEXT }}
        ></div>

        <ul className={list}>
          {data.CINEMA.map((item, idx) => {
            return (
              <li key={item.author + idx}>
                <div className={itemList}>
                  {item.quote ? <p className={qoute}>“{item.quote}”</p> : <></>}

                  <p className={video}>{item.video}</p>

                  <p className={author}>{item.author}</p>
                  <p className={position}>{item.position}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default Cinema;
