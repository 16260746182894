import React from 'react';

import {
  animations,
  top,
  top1,
  top2,
  bottom,
  bottom1,
  bottom2,
} from './animations.module.scss';

const Animations = () => {
  return (
    <div className={animations}>
      <div className={top}>
        <img src="/images/screver-btq/event/top1.gif" alt="" className={top1} />
        <img src="/images/screver-btq/event/top2.gif" alt="" className={top2} />
      </div>

      <div className={bottom}>
        <img
          src="/images/screver-btq/event/bottom1.gif"
          alt=""
          className={bottom1}
        />
        <img
          src="/images/screver-btq/event/bottom2.gif"
          alt=""
          className={bottom2}
        />
      </div>
    </div>
  );
};

export default Animations;
