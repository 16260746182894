import React from 'react';
import { Helmet } from 'react-helmet';

const OcxHelmet = ({ robots = '' }) => {
  return (
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Epilogue:wght@400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      {robots && <meta name="robots" content={robots} />}
    </Helmet>
  );
};

export default OcxHelmet;
