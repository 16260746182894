import React from 'react';

import PagesList from '../PagesList';

const FooterCommon = ({ linksToPages }) => {
  return (
    <footer>
      <div className="scr-ocx-wrap">
        <PagesList linksToPages={linksToPages} />
      </div>
    </footer>
  );
};

export default FooterCommon;
