import React from 'react';
import { Link } from 'gatsby';

import Icon from 'components/UI/Icon';

import { list, link } from './styles.module.scss';

const PagesList = ({ linksToPages }) => {
  return (
    <section>
      <div className="ocx-prog-wrap">
        <ul className={list}>
          {linksToPages.length > 0 ? (
            linksToPages.map((page) => {
              return (
                <li key={page.title}>
                  <Link
                    to={page.url}
                    className={link}
                    id={`link-to-${page.url}`}
                  >
                    <span className="font-epiloque">{page.title}</span>
                    <Icon name="arrow-right-bold" />
                  </Link>
                </li>
              );
            })
          ) : (
            <></>
          )}
        </ul>
      </div>
    </section>
  );
};

export default PagesList;
