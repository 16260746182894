import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

import Icon from 'components/UI/Icon/index';

import { titleStyle, header, link } from './styles.module.scss';

const HeaderCommon = ({
  title,
  url = '/events/screver-ocx-program',
  styleProp = null,
}) => {
  return (
    <header className={classnames(header, styleProp)}>
      <div className="ocx-prog-wrap">
        <Link className={link} to={url} id="back-link-to-agenda">
          <Icon name="arrow-right-bold" />
        </Link>

        <p className={classnames('font-epiloque', titleStyle)}>{title}</p>
      </div>
    </header>
  );
};

export default HeaderCommon;
